import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";
const protegesData = {
  header: (
    <AnimatedLine
      text={
        <>
          House<h1 className="accent h1"> unlike </h1>any other.
        </>
      }
    />
  ),
  landingText: `The Charges of our association are in large measure people with severe intellectual
  disability. Many of them are people in wheelchairs. A large part of them are people with
  behavioural disorders, incontinence of urine, unable to satisfy physiological needs, with
  considerable problems in verbal and non-verbal communication, who will never be able
  to exist independently. The therapy, workshops and educational and revalidation
  activities are focused on long-term effects consisting in excluding antisocial and
  aggressive behaviour, instilling attitudes and reactions that ensure the safety of
  participants and their surroundings. We provide cross-curricular physical development to
  our Charges (swimming pool, hippotherapy, climbing, hiking, sport team games, bike
  and go-kart trips), as well as the acquisition of new skills and help in finding hobbies.`,
  accordiconText: `Once a year we organize a trip the so-called overnight school trip. We run a proprietary
  program of climbing therapy for people with disabilities. Since 2007, we have been the
  initiator and organizer of Jurek Giza Lesser Poland Climbing Competition for Disabled
  People. The association plays an important role for parents of people with disabilities,
  who, thanks to it, can at least partially achieve their goals and career plans.`,
};
export default protegesData;
