import React, { useEffect, useRef, useState } from "react";
import "./cta.scss";
import HeartIcon from "../../assets/SVG/heart.svg";
import gsap from "gsap";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Pomagam from "../../pages/Donate.jsx";

function CTA() {
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    const updateLanguage = () => {
      const currentHashtag = window.location.hash.substring(1);
      if (currentHashtag === "en") {
        setLanguage("en");
      } else {
        setLanguage("pl");
      }
    };
    updateLanguage();
    window.addEventListener("hashchange", updateLanguage);
    return () => window.removeEventListener("hashchange", updateLanguage);
  }, []);

  // GSAP
  const ctaWrapper = useRef(null);
  const ctaTextContainer = useRef(null);
  const ctaText = useRef(null);
  const cta = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(ctaWrapper.current, 0, { visibility: "visible" });
    tl.from(cta.current, 1.6, {
      scale: 0,
      opacity: 0,
      ease: "Elastic.easeOut",
      delay: 2,
    });
    tl.from(ctaTextContainer.current, 0.3, {
      scaleX: 0,
      opacity: 0,
      ease: "power2.easeOut",
    });
    // tl.from(ctaText.current, 0.1, { opacity: 0 });
    // tl.to(ctaText.current, 0.1, { opacity: 0, delay: 3 });
    // tl.to(ctaTextContainer.current, 0.4, { scaleX: 0, opacity: 0, ease: "power2.easeOut" });
  }, []);

  return (
    <Link to={language === "pl" ? "/pomagam" : "/en/pomagam#en"}>
      <div ref={ctaWrapper} className="cta-wrapper">
        <div ref={ctaTextContainer} className="cta__text-container">
          <span ref={ctaText} className="h3 bold cta__text">
            {language === "pl" ? "pomagam" : "donate"}
          </span>
        </div>
        <button ref={cta} className="cta">
          <img src={HeartIcon} alt="" className="cta__icon" />
        </button>
      </div>
    </Link>
  );
}

export default CTA;
