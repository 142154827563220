import React from "react";
import data from "./data/foundationDataEn";
import "./page-styles/foundation.scss";
import Header from "../components/header/Header";
import Hands from "../assets/SVG/hands-holding-heart.svg";
import CTA from "../components/CTA/CTA";
import AnimatedLine from "../components/animatedLine/AnimatedLine";
import Partners from "../components/partners/PartnersEn";

function Foundation() {
  return (
    <main className="foundation page">
      <section className="foundation__landing">
        <Header
          en
          h1={data.h1Genesis}
          content={data.genesis0}
          accordicon={data.genesis}
        />
        <div className="foundation__image-container">
          <img className="foundation__img" src={Hands} alt="" />
        </div>
        <Header en h1={data.h1About} content={data.about} />
        <CTA />
      </section>

      <section>
        <div className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Foundation info" />
        </div>

        <div className="data-container">
          <p className="h3 wide">{data.info}</p>
          <p className="h3 bold wide">Transfer data:</p>
          <p className="h4 medium">Account number:</p>
          <p className="h4 right">80 1240 4588 1111 0011 0099 1078</p>
          <p className="h4 medium">Transfer title:</p>
          <p className="h4 right">Donation</p>
          <p className="h4 medium">Our data:</p>
          <p className="h4 right">
            FOUNDATION "MLEKO I MIÓD" <br /> os. Centrum C 3 / 18 <br /> 31-929
            Krakow
          </p>
          <p className="h3 bold wide">Transfer data from abroad:</p>
          <p className="h4 medium">SWIFT code:</p>
          <p className="h4 right">PKOPPLPW</p>

          <p className="h4 medium">IBAN number:</p>
          <p className="h4 right">PL 80 1240 4588 1111 0011 0099 1078</p>
        </div>
      </section>
      <Partners />
    </main>
  );
}

export default Foundation;
