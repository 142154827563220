import React from "react";
import styled from "styled-components";

import Langusta from "../../assets/SVG/langusta.svg";
import Malak from "../../assets/SVG/malak.webp";
import Subaru from "../../assets/SVG/subbaru.png";
import Paccf from "../../assets/images/paccf.jpg";

import AnimatedLine from "../../components/animatedLine/AnimatedLine";

const Partners = ({ ...rest }) => {
  return (
    <>
      <div style={{ padding: "80px 0" }}>
        <AnimatedLine text="Wspierają nas" />
      </div>
      <PartnersWrapper>
        <a
          href="https://drivingexperience.pl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Subaru} alt="mleko-miod" />
        </a>

        <a
          href="https://www.paccf.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Paccf} alt="mleko-miod" />
        </a>

        <a
          href="https://langustanapalmie.pl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Langusta} alt="mleko-miod" />
        </a>

        <a
          href="https://fundacjamalak.pl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Malak} alt="mleko-miod" />
        </a>
      </PartnersWrapper>
    </>
  );
};
export default Partners;

export const PartnersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10vw;
  align-items: center;

  padding-bottom: 80px;

  & a,
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
