import styled from "styled-components";

export const DefaultSpacing = styled.div`
  word-wrap: normal;

  color: ${({ color }) => color && color};

  h1 {
    font-size: 6rem;
    padding-bottom: 80px;
  }

  h2 {
    font-size: 3rem;
    padding-top: 40px;
  }

  & p {
    padding-bottom: 1em;

    &:last-child {
      padding-bottom: 0;
    }
  }

  & ul {
    list-style-type: disc;
    list-style-position: outside;
    font-weight: 300;
    padding: 20px 0;
  }

  & ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding: 20px 0;
  }

  & li {
    padding-left: 12px;
    padding: 6px 0 0 15px;
  }

  & ul,
  ol,
  li,
  ol,
  p,
  a,
  b,
  strong {
    font-size: 18px;
    line-height: 2;
  }

  b,
  strong {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }
`;
