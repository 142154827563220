//Dependencies
import React, { useRef, useEffect } from "react";
import gsap from "gsap";

//Components
import "./imageContainer.scss";
import Landing from "../../assets/images/landing.jpg";
import Birds from "../../assets/images/birds.png";

function ImageContainer(props) {
  const wrapperImg = useRef(null);
  const landingImg = useRef(null);
  const birdsImg = useRef(null);

  useEffect(() => {
    gsap.from(wrapperImg.current, 2, { y: "100vh", ease: "power3.easeInOut" });
    gsap.from([landingImg.current], 4, {
      ease: "power3.easeInOut",
      scale: 1.5,
      opacity: 0,
    });
    gsap.from(birdsImg.current, 4.2, {
      scale: 0.5,
      x: "8vw",
      y: "8vw",
      ease: "power2",
    });
  });

  return (
    <>
      <div ref={wrapperImg} className={`image-wrapper ${props.cName}`}>
        <img
          ref={birdsImg}
          className="image-wrapper__img-birds"
          src={Birds}
          alt=""
        />
        <div className="image-container">
          <img
            ref={landingImg}
            className="image-container__img"
            src={Landing}
            alt="fundacja mleko i miód"
          />
        </div>
      </div>
    </>
  );
}

export default ImageContainer;
