import React from 'react'
import { NavLink } from "react-router-dom";

const MenuLink = (props) => {
    return (
      <NavLink onClick={()=>{
        props.setState(!props.state)
      }} exact to={props.to} className={`h3 medium menu__link ${props.cName}`}>
        {props.name}
        {props.children}
      </NavLink>
    );
  };

export default MenuLink
