import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import "./animationLine.scss";


function AnimatedLine(props) {
  // Refs for GSAP
  const line = useRef(null);
  const spanline = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.from([line.current, spanline.current], 2, {
      y: 200,
      ease: "power4.out",
      skewY: 15,
      stagger: 0.4,
      onComplete: () => {
        tl.timeScale(1.8);
        // eslint-disable-next-line
        {
          props.reverse && tl.reverse();
        }
      },
    });
  });

  return (
    <div ref={line} className="line">
      <span ref={spanline} className={`h1 bold ${props.cName}`}>
        {props.text}
        {props.children}
      </span>
    </div>
  );
}

export default AnimatedLine;
