import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./pages/App";
import { BrowserRouter as Router } from "react-router-dom";
import SplashScreen from "./pages/SplashScreen";

ReactDOM.render(
  <Router>
    <SplashScreen />
  </Router>,
  // <Router>
  //   <App />
  // </Router>,
  document.getElementById("root")
);
