import React from "react";
import styled from "styled-components";

import RichText from "../components/RichText/RichText";

export const Wrapper = styled.main`
  padding: 120px 0;
`;

const Regulamin = () => {
  return (
    <Wrapper>
      <RichText richText={markdownData} />
    </Wrapper>
  );
};
export default Regulamin;

const markdownData = `# Regulamin przekazywania darowizn na stronie mlekomiod.pl

  
  

## I. Postanowienia wstępne

  

1. Niniejszy regulamin, zwany dalej Regulaminem, określa zasady

świadczenia przez Fundację Mleko i Miód, os. Centrum C 3/18,31- 929

Kraków, KRS: 0000852643, NIP: 6783186017, REGON: 386646007 ,

zwaną dalej Fundacją usługi drogą elektroniczną, polegającej na

umożliwieniu osobom korzystającym ze strony mlekomiod.pl

przekazywanie darowizn pieniężnych, na działania statutowe Fundacji.

2. Ilekroć w niniejszym Regulaminie jest mowa o Darczyńcy, należy przez

to rozumieć każdą osobę korzystającą ze strony mlekomiod.pl w celu

przekazania darowizny.

3. Korzystając ze strony do przekazywania darowizn, Darczyńca

oświadcza i potwierdza, że zapoznał się z treścią Regulaminu i

akceptuje jego postanowienia.

4. Aktualna wersja Regulaminu jest zawsze dostępna dla Darczyńców na

stronie mlekomiod.pl

  

## II. Zasady przekazywania darowizn na stronie

1. Darowiznę można przekazać po kliknięciu „pomagam” na stronie

mlekomiod.pl Po kliknięciu w link należy postępować zgodnie

z instrukcjami zamieszczonymi na stronie operatora płatności.

2. Przekazywanie darowizn na stronie mlekomiod.pl odbywa się za

pośrednictwem operatora płatności – spółka PayPro SA z siedzibą w

Poznaniu,  ul. Pastelowa 8, 60-198 Poznań, NIP 7792369887, REGON

301345068 , KRS 0000347935.

3. Darowiznę można wpłacić przy pomocy przelewu elektronicznego, oraz

systemu płatności mobilnych BLIK.

4. Wpłaty Darczyńców są chronione przy pomocy bezpiecznego

certyfikatu SSL. Dzięki niemu wszystkie przesyłane informacje są

zaszyfrowane.

5. Za pośrednictwem strony można przekazywać darowizny jednorazowe

na rzecz Fundacji.

6. Przekazując darowiznę Darczyńca może wybrać jedną z kwot

sugerowanych na stronie mlekomiod.pl/pomagam lub wpisać dowolną,

wybraną przez siebie kwotę w pole „wpisz kwotę”.

  

## III. Darowizny regularne w postaci płatności cyklicznych

1. Na stronie mlekomiod.pl nie ma możliwości dokonywania płatności

cyklicznych.

  

## IV. Reklamacje, zwroty darowizny, reklamacje usług PayPro

  

1. W przypadku wystąpienia problemów z przekazaniem darowizny,

należy je zgłosić Fundacji pod adresem mailowym

pomagam@mlekomiod.pl

2. Darczyńca może złożyć reklamację, jeżeli Usługi przewidziane w

regulaminach operatora płatności nie zostały zrealizowane lub są

realizowane niezgodnie z jego postanowieniami. Reklamację można

złożyć w formie:

a. pisemnej na adres PayPro S.A.  ul. Pastelowa 8, 60-198 Poznań,

b. elektronicznej poprzez formularz https://www.przelewy24.pl/pomoc

c. telefonicznej pod numerem telefonu: +48616429344

3. Darczyńcy mają prawo zażądać zwrotu darowizny w terminie 7 dni od

jej wpłacenia. W celu uzyskania zwrotu należy wysłać e-mail na

adres: pomagam@mlekomiod.pl Prosimy o umieszczenie w temacie

wiadomości słów: „Zwrot darowizny”. Wpłata, po potrąceniu kosztów

przelewu, zostanie zwrócona w terminie do 7 dni roboczych od dnia

wpływu zgłoszenia do Fundacji.
`;
