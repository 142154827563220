import React from "react";
import "./header.scss";
import Accordicon from "../accordicon/Accordicon";

function Header(props) {
  return (
    <header className={`header-wrapper ${props.cName}`}>
      {props.h1 && <div className="header-wrapper__h1 wrapper">{props.h1}</div>}
      {props.content && (
        <p className={`h4 ${props.cName_p}`}>{props.content}</p>
      )}
      {props.accordicon && (
        <Accordicon content={props.accordicon} en={props.en} />
      )}
      {props.btn}
    </header>
  );
}

export default Header;
