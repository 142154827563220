import React from "react";

function IMGtitle(props) {
  return (
    <div>
      <img className="img" src={props.src} alt={props.title} />
      <h3 style={{color:"#767676",padding:5 }} className="h3">{props.title}</h3>
    </div>
  );
}

export default IMGtitle;
