const linksData = [
  {
    name: { pl: "Dom", en: "Home" },
    url: { pl: "/", en: "/en#en" },
  },
  {
    name: { pl: "Fundacja", en: "Foundation" },
    url: { pl: "/fundacja", en: "/en/fundacja#en" },
  },
  {
    name: { pl: "Podopieczni", en: "Proteges" },
    url: { pl: "/podopieczni", en: "/en/podopieczni#en" },
  },
  {
    name: { pl: "Pomagam", en: "Donate" },
    url: { pl: "/pomagam", en: "/en/pomagam#en" },
  },
];

export default linksData;
