import React from "react";
import data from "./data/protegesData";
import "./page-styles/proteges.scss";
import Header from "../components/header/Header";
import AnimatedLine from "../components/animatedLine/AnimatedLine";
import Video from "../components/Video/Video";

// import images
import Climbing2 from "../assets/images/climbing2.jpg";

import Birds from "../assets/images/birds.png";
import Podpopieczny from "../assets/images/podopieczny.jpg";
import Bufetowa from "../assets/images/bufetowa.jpg";
import Spa from "../assets/images/spa.jpg";
import Silownia from "../assets/images/silownia.jpg";
import Mars from "../assets/images/mars.jpg";
import Grzybobranie from "../assets/images/grzybobranie.jpg";
import Franciszkanie from "../assets/images/franciszkanie.jpg";
import Halina from "../assets/images/halina.jpg";
import IMG from "../components/imgtitle/IMGtitle";
import CTA from "../components/CTA/CTA";

// // import videos
import Przystanek from "../assets/videos/przystanek.mp4";
import Ptaki from "../assets/videos/ptaki.mp4";
import Disco from "../assets/videos/disco.mp4";

// import svg
import Dominny from "../assets/SVG/dominny.svg";
import Discoposter from "../assets/SVG/disko.svg";
import PtakiSVG from "../assets/SVG/ptaki.svg";
import Partners from "../components/partners/Partners";

function Proteges() {
  return (
    <main className="proteges page">
      <section className="proteges__landing">
        <img className="proteges__birds" src={Birds} alt="birds" />

        <Header
          cName="proteges__header"
          h1={data.header}
          content={data.landingText}
          accordicon={data.accordiconText}
        />
        <CTA />
      </section>
      <section className="image-grid-wrapper">
        <div className="image-grid-wrapper__column">
          <IMG title="na grzybobraniu" src={Grzybobranie} />
          <IMG title="na zawodach wspinaczkowych" src={Climbing2} />
          <IMG title="Zbyszek" src={Podpopieczny} />
          <IMG title="w SPA" src={Spa} />
        </div>
        <div className="image-grid-wrapper__column">
          <IMG title="Halina" src={Halina} />
          <IMG title="w konkursie na Bufetową" src={Bufetowa} />
          <IMG title="na siłowni" src={Silownia} />
          <IMG title="lądujący na marsie" src={Mars} />
          <IMG title="w tygodniu franciszkańskim" src={Franciszkanie} />
        </div>
      </section>
      <section>
        <div className="header-wrapper__h1 wrapper">
          <AnimatedLine>
            <>
              Terapia przez <h1 className="h1 accent"> radość</h1>.
            </>
          </AnimatedLine>
        </div>

        <div className="movies">
          <Video source={Disco} poster={Discoposter} />
          <Video source={Przystanek} poster={Dominny} />
          <Video source={Ptaki} poster={PtakiSVG} />
        </div>
      </section>

      <Partners />
    </main>
  );
}

export default Proteges;
