import React, { useState, useEffect } from "react";
import "./footer.scss";
import FooterSection from "./FooterSection";
import FooterLinks from "./FooterLinks";
import { ReactComponent as Logo } from "../../assets/SVG/logo_pion.svg";
function Footer() {
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    const updateLanguage = () => {
      const currentHashtag = window.location.hash.substring(1);
      if (currentHashtag === "en") {
        setLanguage("en");
      } else {
        setLanguage("pl");
      }
    };
    updateLanguage();
    window.addEventListener("hashchange", updateLanguage);
    return () => window.removeEventListener("hashchange", updateLanguage);
  }, []);

  return (
    <footer className="footer">
      <FooterSection title={language === "pl" ? "Kontakt" : "Contact"}>
        {" "}
        tel: +48 668 552 213
        <br />
        pomagam@mlekomiod.pl
      </FooterSection>
      <FooterSection title={language === "pl" ? "Fundacja" : "Foundation"}>
        FUNDACJA „MLEKO I MIÓD" <br />
        os. Centrum C 3 / 18
        <br />
        31-929 Kraków
        <br />
        <br />
        KRS 0000852643 <br />
        REGON 386646007
        <br />
        NIP 6783186017
      </FooterSection>
      <FooterLinks />
      <Logo alt="fundacja mleko i miód - logo svg" className="footer__logo" />
    </footer>
  );
}

export default Footer;
