import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";

const foundationData = {
  h1Genesis: (
    <AnimatedLine
      text={
        <>
          <h1 className="accent--blue h1"> Geneza </h1>powstania.
        </>
      }
    />
  ),
  genesis0: `Fundacja Mleko i Miód powstała odpowiadając na potrzeby Środowiskowego Domu Samopomocy Przystanek Betlejem,
  który jest miejscem dziennego pobytu dorosłych osób z wieloraką niepełnosprawnością.
   Najistotniejszym celem i zadaniem jaki Fundacja stawia sobie w najbliższej przyszłości jest zapewnienie środków finansowych niezbędnych
    do rozbudowy oraz wyposażenia domu tak aby naszym Podopiecznym zaoferować całodobową opiekę w Przystanku Betlejem.`,
  genesis: ` Stowarzyszenie Pomocy Osobom Niepełnosprawnym „Przystanek Betlejem” wyrosło ze 
    społecznego ruchu opartego na idei wspólnot L’Arche. Od 1988 r. nieformalnie, a od 1997r. 
    formalnie jako stowarzyszenie w środowisku lokalnym Krakowa prowadzi dzienny ośrodek wsparcia tzw. Środowiskowy Dom Samopomocy (ŚDS) o nazwie "Przystanek Betlejem". `,
  h1About: (
    <AnimatedLine
      text={
        <>
          Słowo o <h1 className="accent--blue h1"> nazwie </h1>fundacji.
        </>
      }
    />
  ),
  about: `Nazwa Fundacji Mleko i Miód nawiązuje do Starego Testamentu gdzie kraina mlekiem i 
    miodem płynąca oznacza miejsce urodzajne, stwarzające warunki do pełnego rozwoju 
    człowieka.  Dla naszych Podopiecznych często dotkniętych głęboką niepełnosprawnością 
    intelektualną  tym przysłowiowym mlekiem i miodem jest stałe, bezpieczne i przyjazne 
    otoczenie  gdzie nie tylko mogą korzystać z terapii ale przede wszystkim gdzie mogą 
    doświadczać pełni życia.`,

  daneH1: <AnimatedLine text={<>Dane fundacji.</>} />,
  info: (
    <>
      FUNDACJA „MLEKO I MIÓD" <br />
      os. Centrum C 3 / 18 <br />
      31-929 Kraków
      <br />
      <br />
      KRS 0000852643 <br />
      REGON 386646007
      <br />
      NIP 6783186017
    </>
  ),
  info2: (
    <>
      Numer konta:<span className="spacer"></span>80 1240 4588 1111 0011 0099
      1078 <br />
      IBAN:<span className="spacer"></span>PL 80 1240 4588 1111 0011 0099 1078{" "}
      <br />
      KOD SWIFT:<span className="spacer"></span>PKOPPLPW
    </>
  ),
};

export default foundationData;
