import React, { useState, useEffect, useRef } from "react";
import "./page-styles/app.scss";
import { Route, Switch, useLocation } from "react-router-dom";
import "./page-styles/app.scss";
import { motion, AnimatePresence } from "framer-motion";

//Pages
import Homepage from "./Homepage";
import HomepageEn from "./HomepageEn";
import Proteges from "./Proteges";
import ProtegesEn from "./ProtegesEn";
import Donate from "./Donate";
import DonateEn from "./DonateEn";
import Foundation from "./Foundation";
import FoundationEn from "./FoundationEn";
import Polityka from "./Polityka";
import Przelewy24 from "./Przelewy24";
import Regulamin from "./Regulamin";
import Sprawozdania from "./Sprawozdania";
import PolitykaEn from "./PolitykaEn";
import Przelewy24En from "./Przelewy24En";
import RegulaminEn from "./RegulaminEn";
import SprawozdaniaEn from "./SprawozdaniaEn";

// Components
import ScrollToTop from "../components/ScrollToTop";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const routes = [
  { path: "/", name: "Dom", Component: Homepage },
  { path: "/fundacja", name: "Fundacja", Component: Foundation },
  { path: "/podopieczni", name: "Podopieczni", Component: Proteges },
  { path: "/pomagam", name: "Pomagam", Component: Donate },
  {
    path: "/polityka-prywatnosci",
    name: "Polityka-Prywatności",
    Component: Polityka,
  },
  { path: "/przelewy24", name: "Przelewy24", Component: Przelewy24 },
  { path: "/regulamin", name: "Regulamin", Component: Regulamin },
  {
    path: "/sprawozdania-finanowe",
    name: "Sprawozdania-finansowe",
    Component: Sprawozdania,
  },
  { path: "/en", name: "Home", Component: HomepageEn },
  { path: "/en/fundacja", name: "Fundacja", Component: FoundationEn },
  { path: "/en/podopieczni", name: "Podopieczni", Component: ProtegesEn },
  { path: "/en/pomagam", name: "Pomagam", Component: DonateEn },
  {
    path: "/en/polityka-prywatnosci",
    name: "Polityka-Prywatności",
    Component: PolitykaEn,
  },
  { path: "/en/przelewy24", name: "Przelewy24", Component: Przelewy24En },
  { path: "/en/regulamin", name: "Regulamin", Component: RegulaminEn },
  {
    path: "/en/sprawozdania-finanowe",
    name: "Sprawozdania-finansowe",
    Component: SprawozdaniaEn,
  },
];

function App() {
  const fadeIn = {
    hidden: { opacity: 0, y: -300 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.8,
        ease: "easeInOut",
      },
    },
  };

  const fadeOut = {
    hidden: { opacity: 0, y: -300 },
    visible: {
      opacity: 1,
      y: -300,
      transition: {
        duration: 1.8,
        ease: "easeInOut",
      },
    },
  };

  const location = useLocation();

  let loadCount = useRef(0);
  useEffect(() => {
    loadCount.current = loadCount.current + 1;
  });

  const delay = 0.4;

  return (
    <>
      <AnimatePresence>
        <motion.div variants={fadeIn} initial="hidden" animate="visible">
          <Navbar />
        </motion.div>
        {/* <AnimatedCursor /> */}
        <Switch location={location} key={location.key}>
          {routes.map(({ path, Component, name }) => (
            <Route key={name} path={path} exact>
              <motion.div
                className="page-wrapper"
                initial={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -200 }}
                transition={{ duration: 0.4 }}
              >
                {loadCount.current <= 1 && (
                  <motion.div
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                  >
                    <ScrollToTop />
                    <Component />
                    <Footer />
                  </motion.div>
                )}
                {loadCount.current > 1 && (
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.47, ease: "easeOut" }}
                  >
                    <ScrollToTop />
                    <Component />
                    <Footer />
                  </motion.div>
                )}
              </motion.div>
            </Route>
          ))}
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
