import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";

const donateData = {
  header: (
    <>
      <AnimatedLine
        text={
          <>
            Twoje<h1 className="accent h1">wsparcie </h1>
          </>
        }
      />
      <AnimatedLine text={<>wiele dla nas znaczy.</>} />
    </>
  ),
};

export default donateData;
