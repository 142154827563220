import React from "react";
import AnimatedLine from "../components/animatedLine/AnimatedLine";

function Przelewy24() {
  return (
    <div>
      <div className="header-wrapper__h1 wrapper">
        <AnimatedLine text="Przelewy 24" />
      </div>
      <p className="h4">
        <b className="h3 bold"> Oświadczenia: </b>
        <br />
        <br />
        1. Wyrażam zgodę na udostępnienie moich danych osobowych obejmujących
        adres email firmie PayPro S.A. ul. Pastelowa 8, 60-198 Poznań, która
        jest właścicielem systemu <b className="h4 bold">Przelewy24</b> celem
        dokonania płatności. <br />
        <br />
        2. Oświadczam, iż zapoznałam/em się z regulaminem płatności firmy PayPro
        S.A. w Poznaniu <b className="h4 bold">Przelewy24</b> jako pośrednika
        płatności dokonywanych na rzecz Fundacji Mleko i Miód. <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <b className="h3 bold">
          Informacja dodatkowa o przetwarzaniu danych osobowych:
        </b>
        <br />
        <br />
        a) do przetwarzania danych zastosowanie znajdują przepisy RODO oraz
        przepisy polskich ustaw uzupełniających regulację RODO, <br />
        <br />
        b) stosowanie do art.13 ust. 1 lit.e) RODO odbiorcami danych, w celach
        związanych ze świadczeniem usług płatniczych udostępnianych na stronie
        Portalu będą dostawcy usług płatniczych, <br />
        <br />
        c) dostawcy usług płatniczych jako odbiorcy danych osobowych, będą
        administratorami danych, które to dane będą przetwarzali w celach
        związanych ze świadczeniem usług płatniczych, w szczególności w zakresie
        niezbędnym do zapobiegania oszustwom związanym z wykonywanymi usługami
        płatniczymi oraz dochodzenia i wykrywania tego rodzaju oszustw, a także
        identyfikacji w zakresie wynikającym z przepisów prawa, <br />
        <br />
        d) dostawcy usług płatniczych poza moimi danymi osobowymi mu
        udostępnionymi mogą również przetwarzać dane osobowe zebrane
        samodzielnie lub otrzymane od innych podmiotów, w szczególności od
        Instytucji pośredniczącej (zwłaszcza innych dostawców usług płatniczych){" "}
        <br />
        <br />
      </p>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Przelewy24;
