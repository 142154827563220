import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";

const foundationData = {
  h1Genesis: (
    <AnimatedLine
      text={
        <>
          <h1 className="accent--blue h1"> The genesis</h1> of the
          establishment.
        </>
      }
    />
  ),
  genesis0: `Fundacja Mleko i Miód (The Milk and Honey Foundation) was established in response to
  the needs of Środowiskowy Dom Samopomocy Przystanek Betlejem (the Community
  Self-Help Centre “Przystanek Betlejem”), which is a place of day care for adults with multiple
  disabilities. The most important goal and task that the Foundation sets itself in the
  foreseeable future is to provide funds necessary for the extension and furnishing of the
  house so that we can offer our pupils 24-hour care at the “Przystanek Betlejem”.`,
  genesis: `Stowarzyszenie Pomocy Osobom Niepełnosprawnym “Przystanek Betlejem” (The
    Association for the Disabled People "Przystanek Betlejem") emerged from a social
    movement based on the idea of L&#39;Arche communities. Since 1988 informally, and since
    1997 formally, as an association in the local community of Kraków, it has been running a
    daily support centre, the so-called Środowiskowy Dom Samopomocy “Przystanek
    Betlejem” (the Community Self-Help Centre "Przystanek Betlejem").`,
  h1About: (
    <AnimatedLine
      text={
        <>
          A few words <h1 className="accent--blue h1"> about the name </h1> of
          the foundation.
        </>
      }
    />
  ),
  about: `The name of Fundacja Mleko i Miód (the Milk and Honey Foundation) refers to the Old
  Testament, where the land of milk and honey means a fertile place, creating conditions
  for full human development. For our Charges, often suffering from severe intellectual
  disability, this proverbial milk and honey is a permanent, safe and friendly environment
  where they could not only benefit from therapy, but above all, live their lives to the
  fullest.`,

  daneH1: <AnimatedLine text={<>Foundation data</>} />,
  info: (
    <>
      FUNDACJA „MLEKO I MIÓD" <br />
      os. Centrum C 3 / 18 <br />
      31-929 Kraków
      <br />
      <br />
      KRS 0000852643 <br />
      REGON 386646007
      <br />
      NIP 6783186017
    </>
  ),
  info2: (
    <>
      Account number:<span className="spacer"></span>80 1240 4588 1111 0011 0099
      1078 <br />
      IBAN:<span className="spacer"></span>PL 80 1240 4588 1111 0011 0099 1078{" "}
      <br />
      SWIFT CODE:<span className="spacer"></span>PKOPPLPW
    </>
  ),
};

export default foundationData;
