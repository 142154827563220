import React from "react";
import { Link } from "react-router-dom";
import "./form.scss";
import RadioInput from "./RadioInput";
import CustomValueInput from "./CustomValueInput";

class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      idSprzedawcy: "124656",
      tytułPłatności: "Darowizna na rzecz fundacji Mleko i Miod",
      kluczCRC: "48ebf86bac189ba1",
      returnURL: "https://mlekomiod.pl/pomagam",

      name: "",
      email: "",
      amount: "",
      acitve: true,

      paymentAgreement: false,
      privacy: false,
    };
  }

  handleChange = (e) => {
    const { name, value, onFocus } = e.target;
    this.setState({
      [name]: value,
      active: onFocus,
    });
  };
  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  render() {
    return (
      <form
        className="form-container"
        method="get"
        action="https://sklep.przelewy24.pl/zakup.php"
      >
        <input
          type="hidden"
          name="z24_id_sprzedawcy"
          value={this.state.idSprzedawcy}
        />
        <input type="hidden" name="z24_crc" value={this.state.kluczCRC} />
        <input
          type="hidden"
          name="z24_return_url"
          value={this.state.returnURL}
        />
        <input
          type="hidden"
          name="z24_nazwa"
          value={this.state.tytułPłatności}
        />
        <input
          type="hidden"
          name="z24_kwota"
          value={this.state.amount ? this.state.amount * 100 : 0}
        />
        <div className="radioInput-container">
          <RadioInput
            value="10"
            state={this.state}
            handleChange={this.handleChange}
          />
          <RadioInput
            value="25"
            state={this.state}
            handleChange={this.handleChange}
          />
          <RadioInput
            value="50"
            state={this.state}
            handleChange={this.handleChange}
          />
          <RadioInput
            value="100"
            state={this.state}
            handleChange={this.handleChange}
          />
          <CustomValueInput
            state={this.state}
            handleChange={this.handleChange}
          />
        </div>

        <label className="form-text-container">
          <p className="h4 medium">Email</p>
          <input
            required
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            placeholder="Kowalski@email.pl"
          />
        </label>

        <label className="form-checkbox">
          <input
            required
            type="checkbox"
            name="paymentAgreement"
            onChange={this.handleCheckbox}
            checked={this.state.paymentAgreement}
          />
          <p className="h5">
            Wyrażam zgodę na udostępnienie moich danych osobowych obejmujących
            adres email firmie PayPro S.A. ul. Pastelowa 8, 60-198 Poznań, która
            jest właścicielem systemu{" "}
            <Link target="_blank" to="/przelewy24">
              <span className="h5 bold">przelewy24</span>
            </Link>{" "}
            celem dokonania płatności.
          </p>
        </label>

        <label className="form-checkbox">
          <input
            required
            type="checkbox"
            name="privacy"
            onChange={this.handleCheckbox}
            checked={this.state.privacy}
          />
          <p className="h5">
            {" "}
            Oświadczam, iż zapoznałam/em się z polityką prywatności firmy PayPro
            S.A. w Poznaniu (Przelewy24) jako pośrednika płatności dokonywanych
            na rzecz Fundacji Mleko i Miód.
          </p>
        </label>

        <button className="submit-button h4 bold">Przekaż darowiznę</button>
      </form>
    );
  }
}

export default Form;
