//Dependencies
import React from "react";
import "./page-styles/homepage.scss";

//Components
import data from "./data/homepageDataEn";
import Header from "../components/header/Header";
import ImageContainer from "../components/imageContainer/ImageContainer";
import CTA from "../components/CTA/CTA";

//Images
import Birds from "../assets/images/birds.png";
import Hands from "../assets/SVG/hands.svg";
import Partners from "../components/partners/PartnersEn";

function Homepage() {
  return (
    <main className="homepage page">
      <section className="homepage-landing">
        <div className="homepage-landing__heading wrapper">
          {data.headerSlogan}
          <div className="homepage-landing__content h4">{data.landingText}</div>
          <CTA />
        </div>

        <ImageContainer cName="homepage__img-container" />
      </section>

      <section className="homepage-quote">
        <article className="homepage-quote__text h3">{data.quote}</article>
        <img
          className="homepage-quote__img homepage-quote__img--top"
          src={Birds}
          alt="Birds"
        />
        <img
          className="homepage-quote__img homepage-quote__img--bottom"
          src={Birds}
          alt="Birds"
        />
      </section>

      <section className="homepage-about">
        <Header
          cName="homepage-about__text"
          h1={data.headerMotivation}
          content={data.textMotivation}
          accordicon={data.accordiconMotivation}
          en
        />
        <img className="homepage-about__hands" src={Hands} alt="" />
        <Header
          cName="homepage-about__text"
          h1={data.headerPlans}
          content={data.textPlans}
          accordicon={data.accordiconPlans}
          en
        />
      </section>

      <Partners />
    </main>
  );
}

export default Homepage;
