import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine";

const homepageData = {
  headerSlogan: (
    <>
      <AnimatedLine cName="center-header" text="Let's build" />
      <AnimatedLine cName="center-header" text="a house" />
      <AnimatedLine
        cName="accent center-header"
        text="unlike any other."
      />{" "}
    </>
  ),
  landingText: `The objective of our organization is broadly defined help for the disabled and their
families. Organizing therapy, care, rehabilitation and leisure in a friendly environment.`,

  quote: (
    <>
      "The disabled are fully human subjects with their natural, holy and
      inviolable rights, which, despite the limitations and suffering inherent
      in their body and powers, nevertheless constitute a special meaning of
      human dignity and greatness." <br />
      <br />- John Paul II
    </>
  ),
  headerMotivation: (
    <AnimatedLine
      text={
        <>
          Our<h1 className="accent h1"> motivation</h1>.
        </>
      }
    />
  ),
  textMotivation: `Our Centre provides day care for our Charges. Outside the office hours of our Centre,
  the burden of care rests on the parents of our charges, who pursue their parenthood
  heroically.`,
  accordiconMotivation: `  However, we can see that their resources are weakening. In the coming years, we expect
  that some of our disabled friends will lose their increasingly weak, elderly parents and,
  consequently, they will be placed in a nursing home. Thus, they will lose the bonds they
  have gained, that are extremely important in the life of every human being.`,
  headerPlans: (
    <AnimatedLine
      text={
        <>
          Our<h1 className="accent h1"> plans</h1>.
        </>
      }
    />
  ),
  textPlans: `We are planning to accept about 20 people for permanent residence to our house. We
  want to provide about 5 places for an ad hoc, intervention stay, the so-called respite
  care, where the parent will be able to leave their son/daughter to go, for example, to a
  hospital, sanatorium, or to visit friends or family.  
`,
  accordiconPlans: (
    <>
      In order to do this, it will be necessary to expand the infrastructure
      that we currently use. We want to build a floor on the existing house with
      a winter garden on the roof and fully adapt it to the needs of our
      disabled friends. It is necessary to install a lift, organize a
      physiotherapist&#39;s room, a doctor&#39;s consulting room and equipment
      in order to ensure the implementation of activities at the highest
      possible level. We cannot do it ourselves. We count on open hearts of the
      Donors. <br />
      <br />
      Thank you
    </>
  ),
};

export default homepageData;
