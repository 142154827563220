import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "./Hamburger";
import MenuLink from "./MenuLink";
import linksData from "./linksData";
import "./navbar.scss";
import { ReactComponent as Logo } from "../../assets/SVG/logo-poziom.svg";
import { ReactComponent as TranslateIcon } from "../../assets/SVG/translate.svg";
import { Link, useHistory } from "react-router-dom";

function Navbar() {
  const [state, setState] = useState(true);
  const router = useHistory();

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    const updateLanguage = () => {
      const currentHashtag = window.location.hash.substring(1);
      if (currentHashtag === "en") {
        setLanguage("en");
      } else {
        setLanguage("pl");
      }
    };
    updateLanguage();
    window.addEventListener("hashchange", updateLanguage);
    return () => window.removeEventListener("hashchange", updateLanguage);
  }, []);

  const links = linksData.map((item) => {
    return (
      <MenuLink
        state={state}
        setState={setState}
        key={item.name[language]}
        to={item.url[language]}
        name={item.name[language]}
      />
    );
  });

  return (
    <nav className={`navbar`}>
      <Link to={language === "pl" ? "/" : "/en#en"}>
        <Logo
          className={`navbar__logo navbar__logo--index ${state ? "" : "light"}`}
        />
      </Link>

      <div className={`navbar__menu menu ${state ? "" : "menu--open"}`}>
        <div className="menu__links-container">{links}</div>
        <div className={`menu__links-container-secondary`}>
          <button
            className="menu__link--secondary h3 medium menu__link noActive"
            value="pl"
            onClick={() => {
              setLanguage("pl");
              router.push("/");
            }}
          >
            polski
          </button>
          <button
            className="menu__link--secondary h3 medium menu__link noActive"
            value="en"
            name="english"
            onClick={() => {
              setLanguage("en");
              router.push("/en#en");
            }}
          >
            english
          </button>
          <TranslateIcon className="menu__translate light" />
        </div>
      </div>
      <Hamburger cName="navbar__hamburger" state={state} setState={setState} />
    </nav>
  );
}

export default Navbar;
