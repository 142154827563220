import React, { useState } from "react";
import data from "./data/donateDataEn";
import "./page-styles/donate.scss";
import DonateImg from "../assets/SVG/donate.svg";

import AnimatedLine from "../components/animatedLine/AnimatedLine";

import Partners from "../components/partners/PartnersEn";

function Donate() {
  const [krsNumber, setKrsNumber] = useState("0000852643");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(krsNumber);
      alert("KRS number copied");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <main className="donate page">
      <section className="donate-landing">
        <div className="donate-landing__heading wrapper">{data.header}</div>
        <img className="donate-landing__img" src={DonateImg} alt="" />
      </section>

      <section>
        <div id="#donate" className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Traditional transfer" />
        </div>
        <div className="data-container">
          <p className="h3 bold wide">Data for a traditional transfer:</p>
          <p className="h4 medium">Account number:</p>
          <p className="h4 right">80 1240 4588 1111 0011 0099 1078</p>

          <p className="h4 medium">Transfer title:</p>
          <p className="h4 right">Darowizna</p>
          <p className="h4 medium">Adress:</p>
          <p className="h4 right">
            FUNDACJA „MLEKO I MIÓD" <br /> os. Centrum C 3 / 18 <br /> 31-929
            Kraków
          </p>
          <p className="h3 bold wide">Data for transfer from abroad:</p>
          <p className="h4 medium">SWIFT:</p>
          <p className="h4 right">PKOPPLPW</p>

          <p className="h4 medium">IBAN:</p>
          <p className="h4 right">PL 80 1240 4588 1111 0011 0099 1078</p>
        </div>
      </section>

      <section style={{ marginTop: 160 }}>
        <div className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Podaruj 1,5% podatku" />
        </div>
        <div className="data-container">
          <p className="h4 medium">KRS number:</p>
          <p className="h4 right">0000852643</p>
          <button className="submit-button h4 bold" onClick={copyToClipboard}>
            Copy the KRS number
          </button>
        </div>
        <div style={{ display: "grid", maxWidth: 480, gap: 16 }}>
          <p className="h4">
            Donating 1.5% of your tax to the "Mleko Miód" foundation is a way to
            help support for people with disabilities and their families. Help
            them in rehabilitation and rest.
          </p>
          <a
            href="https://www.podatki.gov.pl/pit/twoj-e-pit/"
            target="_blank"
            rel="noopener noreferrer"
            className="submit-button h4 bold"
            style={{
              width: "fit-content",
              display: "grid",
              placeItems: "center",
            }}
          >
            Donate 1.5%
          </a>
        </div>
      </section>

      <Partners />
    </main>
  );
}

export default Donate;
