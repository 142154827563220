import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";

const donateData = {
  header: (
    <>
      <AnimatedLine
        text={
          <>
            Your <h1 className="accent h1">support </h1>
          </>
        }
      />
      <AnimatedLine text={<>means a lot to us.</>} />
    </>
  ),
};

export default donateData;
