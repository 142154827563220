import React, { useRef, useEffect, useState, Suspense } from "react";
import AnimatedLine from "../components/animatedLine/AnimatedLine";
import "./page-styles/splashscreen.scss";
import gsap from "gsap";
import { ReactComponent as Logo } from "../assets/SVG/logo_pion.svg";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import ReactDOM from "react-dom";

const HomeLazy = React.lazy(() => import("./Homepage"));

function SplashScreen() {
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    const updateLanguage = () => {
      const currentHashtag = window.location.hash.substring(1);
      if (currentHashtag === "en") {
        setLanguage("en");
      } else {
        setLanguage("pl");
      }
    };
    updateLanguage();
    window.addEventListener("hashchange", updateLanguage);
    return () => window.removeEventListener("hashchange", updateLanguage);
  }, []);

  const frame = useRef(null);
  const logoIcon = useRef(null);
  const splash = useRef(null);
  const text = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.to(splash.current, 0, { visibility: "visible" });
    tl.from(text.current, 0.9, { opacity: 0, y: 50, ease: "power2" });
    tl.to(logoIcon.current, 0.1, { zIndex: 1, delay: 3.3 }, "-=.9");
    tl.from(frame.current, 1.5, { x: "120vw", skewX: -3, ease: "power2" });
    tl.to(text.current, 0, { opacity: 0 }, "-=.9");
    tl.to(frame.current, 1.5, {
      x: "-120vw",
      ease: "power2",
    });
    tl.to(
      splash.current,
      0,
      {
        opacity: 0,
        onComplete: () => {
          ReactDOM.render(
            <Router>
              <App />
            </Router>,
            document.getElementById("root")
          );
        },
      },
      "-=.4"
    );
  });

  return (
    <div ref={splash} className="splash">
      {language === "pl" && (
        <div className="splash__wrapper wrapper">
          <AnimatedLine reverse={true} text="Dla tych," />
          <AnimatedLine reverse={true} text="którzy po swoje szczęscie" />
          <AnimatedLine reverse={true} text="sami sięgnąć nie mogą" />
        </div>
      )}

      {language !== "pl" && (
        <div className="splash__wrapper wrapper">
          <AnimatedLine reverse={true} text="For those," />
          <AnimatedLine
            reverse={true}
            text="who cannot reach for their own happiness"
          />
          <AnimatedLine reverse={true} text="on their own" />
        </div>
      )}

      <h4 ref={text} className="h4 medium splash__text">
        ŚDS Przystanek Betlejem & Fundacja Mleko i Miód
      </h4>
      <Logo ref={logoIcon} className="splash__img-logo" />
      <div ref={frame} className="splash__frame"></div>
      <Suspense fallback={<div>Loading...</div>}>
        <div style={{ display: "hidden" }}>
          <HomeLazy />
        </div>
      </Suspense>
    </div>
  );
}

export default SplashScreen;
