import React from 'react'

function RadioInput({ value, state, handleChange }) {
    return (
      <label className={`form-amount-btn ${state.amount == value ? "set" : ""}`}>
        <p className="h4 medium">{value}PLN</p>
        <input
          style={{ visibility: "hidden" }}
          required={state.amount > 0 ? false : true}
          type="radio"
          name="amount"
          value={value}
          checked={state.amount === value}
          onChange={handleChange}
          onFocus={state.acitve}
        />{" "}
      </label>
    );
  }

export default RadioInput
