import React from "react";

function FooterSection(props) {
  return (
    <section className="footer__section">
      <div className="h2 bold light">{props.title}</div>
      <div className="h4 light">
        {props.children}
      </div>
    </section>
  );
}

export default FooterSection;
