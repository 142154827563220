import React from "react";
import AnimatedLine from "../components/animatedLine/AnimatedLine";

function Polityka() {
  return (
    <div>
      <div className="header-wrapper__h1 wrapper">
        <AnimatedLine text="Polityka prywatności" />
      </div>
      <p className="h4">
        <b className="h3 bold">
          Informacje o przetwarzaniu danych osobowych przez Fundację zgodnie z RODO – Polityka
          prywatności Fundacji.{" "}
        </b><br /><br />
        1. Fundacja przykłada szczególną uwagę do ochrony prywatności oraz do ochrony poufności
        przekazywanych informacji a w szczególności o należytą ochronę danych osobowych oraz nie
        ujawniania ich osobom trzecim, za wyjątkiem przewidzianych przez przepisy prawa
        okoliczności, gdy tego rodzaju ujawnienie jest obowiązkowe. <br /><br />
        2. Każdy podający swoje dane w ramach usług świadczonych poprzez informacyjny serwis
        internetowy Fundacji wyraża zgodę na przetwarzanie swoich danych osobowych w celu
        korzystania z tych usług w ramach w/w serwisu internetowego. Fundacja przetwarza dane
        osobowe na podstawie zawartej umowy o świadczenie usług drogą elektroniczną. Podstawa prawna
        przetwarzania danych to art. 6 ust. 1 lit. b Rozporządzenia Parlamentu Europejskiego i Rady
        UE nr 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
        uchylenia dyrektywy 95/46/WE, dalej zwanego „RODO”. <br /><br />
        3. Podanie danych osobowych jest dobrowolne, przy czym może być konieczne do skorzystania z
        niektórych usług świadczonych poprzez informacyjny serwis internetowy Fundacji. <br /><br />
        4. Administratorem danych osobowych jest Fundacja. <br /><br />
        5. Fundacja przetwarza dane osobowe jedynie w zakresie i okresie niezbędnym do świadczenia
        usług. <br /><br />
        6. Fundacja chroni dane osobowe w szczególności przed dostępem, pozyskiwaniem i
        modyfikowaniem ich przez osoby nieuprawnione przy zastosowaniu odpowiednich sprzętowych oraz
        programowych zabezpieczeń, dokładając najwyższej staranności wymaganej dla tego rodzaju
        usług i rodzaju przetwarzanych informacji. <br /><br />
        7. Każdy korzystający ma prawo: <br /><br />
        a) w każdym czasie dostępu do swoich danych osobowych, ich sprostowania lub ograniczenia ich
        przetwarzania oraz prawo wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych w
        przypadkach prawem przewidzianych, <br /><br />
        b) otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu
        maszynowego dane osobowe jego dotyczące, które podał Fundacji i ma prawo przesłać te dane
        innemu administratorowi, jak również może żądać przesłania przez Fundację swoich danych
        osobowych innemu administratorowi danych, zgodnie z art. 20 RODO, <br /><br />
        c) wnieść skargę do organu nadzorczego w razie przetwarzania przez Fundację jego danych
        osobowych niezgodnie z obowiązującymi przepisami. <br /><br />
      </p>
      <br /><br /><br /><br />
    </div>
  );
}

export default Polityka;
