import React, { useState } from "react";
import data from "./data/donateData";
import "./page-styles/donate.scss";
import DonateImg from "../assets/SVG/donate.svg";

import AnimatedLine from "../components/animatedLine/AnimatedLine";
import Form from "../components/form/Form";
import Partners from "../components/partners/Partners";

function Donate() {
  const [krsNumber, setKrsNumber] = useState("0000852643");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(krsNumber);
      alert("Skopiowano number KRS");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <main className="donate page">
      <section className="donate-landing">
        <div className="donate-landing__heading wrapper">{data.header}</div>
        <img className="donate-landing__img" src={DonateImg} alt="" />
      </section>

      <section>
        <div id="#donate" className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Przelew Online" />
        </div>
        <Form />
      </section>

      <section>
        <div id="#donate" className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Przelew tradycyjny" />
        </div>
        <div className="data-container">
          <p className="h3 bold wide">Dane do przelewu tradycyjnego:</p>
          <p className="h4 medium">Numer konta:</p>
          <p className="h4 right">80 1240 4588 1111 0011 0099 1078</p>

          <p className="h4 medium">Tytuł przelewu:</p>
          <p className="h4 right">Darowizna</p>
          <p className="h4 medium">Nasze dane:</p>
          <p className="h4 right">
            FUNDACJA „MLEKO I MIÓD" <br /> os. Centrum C 3 / 18 <br /> 31-929
            Kraków
          </p>
          <p className="h3 bold wide">Dane do przelewu z zagranicy:</p>
          <p className="h4 medium">Kod SWIFT:</p>
          <p className="h4 right">PKOPPLPW</p>

          <p className="h4 medium">Numer IBAN:</p>
          <p className="h4 right">PL 80 1240 4588 1111 0011 0099 1078</p>
        </div>
      </section>

      <section style={{ marginTop: 160 }}>
        <div className="header-wrapper__h1 wrapper">
          <AnimatedLine text="Podaruj 1,5% podatku" />
        </div>
        <div className="data-container">
          <p className="h4 medium">Numer KRS:</p>
          <p className="h4 right">0000852643</p>
          <button className="submit-button h4 bold" onClick={copyToClipboard}>
            Skopiuj number KRS
          </button>
        </div>
        <div style={{ display: "grid", maxWidth: 480, gap: 16 }}>
          <p className="h4">
            Przekazanie 1,5% podatku na fundację "Mleko miód" to sposób na
            wsparcie osób niepełnosprawnych i ich rodzin. Pomóż im w
            rehabilitacji i wypoczynku.
          </p>
          <a
            href="https://www.podatki.gov.pl/pit/twoj-e-pit/"
            target="_blank"
            rel="noopener noreferrer"
            className="submit-button h4 bold"
            style={{
              width: "fit-content",
              display: "grid",
              placeItems: "center",
            }}
          >
            Podaruj 1,5%
          </a>
        </div>
      </section>
      <Partners />
    </main>
  );
}

export default Donate;
