import React from "react";
import { compiler } from "markdown-to-jsx";

import * as S from "./RichText.styles";

const RichText = ({ richText, color, ...rest }) => {
  const compiledText = compiler(richText, {
    wrapper: null,
  });

  return <S.DefaultSpacing>{compiledText}</S.DefaultSpacing>;
};

export default RichText;
