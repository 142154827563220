import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import "./accordicon.scss";

function Accordicon(props) {
  // Set Open
  const [isOpen, setOpen] = useState(false);

  // Refs for GSAP
  const accordiconWrapper = useRef(null);
  const accordiconContent = useRef(null);

  // Opening animation after state change
  useEffect(() => {
    const accordiconHeight = accordiconContent.current.offsetHeight;
    if (!isOpen) {
      gsap.to(accordiconWrapper.current, 0.4, {
        height: 0,
        ease: "power3.easeInOut",
      });
    } else {
      gsap.to(accordiconWrapper.current, 0.4, {
        height: `${accordiconHeight}px`,
        ease: "power3.easeInOut",
      });
    }
  }, [isOpen]);

  // State Handler
  const setOpenHandler = () => {
    setOpen(!isOpen);
  };

  // Component
  return (
    <div className="Accordicon">
      <div ref={accordiconWrapper} className="Accordicon__wrapper">
        <p ref={accordiconContent} className="Accordicon__content h4">
          <br />
          {props.content}
        </p>
      </div>
      <div onClick={setOpenHandler} className="Accordicon__button h3 bold">
        {props.en
          ? !isOpen
            ? "read more // expand"
            : "collapse"
          : !isOpen
          ? "czytaj dalej // rozwiń"
          : "zwiń"}
      </div>
    </div>
  );
}

export default Accordicon;
