import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine";

const homepageData = {
  headerSlogan: (
    <>
      <AnimatedLine cName="center-header" text="Wspólnymi siłami" />
      <AnimatedLine cName="center-header" text="zbudujmy dom" />
      <AnimatedLine
        cName="accent center-header"
        text="inny niż wszystkie."
      />{" "}
    </>
  ),
  landingText:
    "Celem naszej organizacji jest szeroko rozumiana pomoc osobom niepełnosprawnym i ich rodzinom. Organizowanie terapii, opieki, rehabilitacji i wypoczynku w przyjaznym środowisku.",

  quote: (
    <>
      „Osoby niepełnosprawne w pełni są podmiotami ludzkimi z należnymi im
      wrodzonymi, świętymi i nienaruszalnymi prawami, które mimo ograniczeń
      cierpień wpisanych w ich ciało i władze, stanowią jednak o szczególnym
      znaczeniu godności i wielkości człowieka." <br />
      <br />- Jan Paweł II
    </>
  ),
  headerMotivation: (
    <AnimatedLine
      text={
        <>
          Nasza<h1 className="accent h1"> motywacja</h1>.
        </>
      }
    />
  ),
  textMotivation: `Nasz Ośrodek zapewnia opiekę dzienną dla naszych Podopiecznych. Poza godzinami funkcjonowania naszego Ośrodka, ciężar opieki spoczywa na rodzicach Naszych Podopiecznych którzy wręcz heroicznie realizują swoje rodzicielstwo.`,
  accordiconMotivation: `  Widzimy jednak że ich zasoby słabną. W nadchodzących latach spodziewamy się, że kilku naszych niepełnosprawnych Przyjaciół straci coraz bardziej słabych i starszych rodziców a co za tym idzie będą umieszczeni w Domu Pomocy Społecznej. Tym samym utracą zdobyte więzi, tak ważne w życiu każdego człowieka.`,
  headerPlans: (
    <AnimatedLine
      text={
        <>
          Nasze<h1 className="accent h1"> plany</h1>.
        </>
      }
    />
  ),
  textPlans: `Planujemy aby dom przyjął około 20 osób na pobyt stały. Pragniemy zabezpieczyć około 5 miejsc na pobyt doraźny, interwencyjny tzw. opiekę wytchnieniową, gdzie rodzic będzie mógł zostawić swojego syna/córkę, aby udać się np. do szpitala, sanatorium czy w odwiedziny do znajomych bądź rodziny.  
`,
  accordiconPlans: (
    <>
      Aby to zrobić konieczna będzie rozbudowa infrastruktury, z której obecnie
      korzystamy. Chcemy wybudować piętro na istniejącym domu wraz z ogrodem
      zimowym na dachu i w pełni dostosować do potrzeb naszych niepełnosprawnych
      Przyjaciół. Koniecznym jest zainstalowanie windy, organizacja gabinetu
      fizjoterapeuty, gabinetu lekarskiego oraz wyposażenie, gwarantujące
      realizację działań na możliwie najwyższym poziomie. Sami tego nie
      dokonamy. Liczymy na otwarte serca Darczyńców. <br />
      <br />
      Dziękujemy!
    </>
  ),
};

export default homepageData;
