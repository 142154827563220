import React from "react";
import AnimatedLine from "../../components/animatedLine/AnimatedLine.jsx";
const protegesData = {
  header: (
    <AnimatedLine
      text={
        <>
          Dom<h1 className="accent h1"> inny </h1>niż wszystkie.
        </>
      }
    />
  ),
  landingText: `Podopieczni naszego stowarzyszenia to w znacznej mierze osoby o głębokim stopniu niepełnosprawności intelektualnej. 
    Wielu z nich to osoby na wózkach. Spora część to osoby z zaburzeniami zachowania, niekontrolujące i niesygnalizujące 
    potrzeb fizjologicznych, ze sporymi kłopotami w komunikacji werbalnej i pozawerbalnej, które nigdy samodzielnie nie będą 
    mogły egzystować. Terapia, prace warsztatowe i zajęcia edukacyjne oraz rewalidacyjne są ukierunkowane na długofalowe 
    efekty polegające na wykluczeniu zachowań aspołecznych i agresywnych, wpajaniu postaw i odruchów zapewniających 
    bezpieczeństwo uczestnikom i ich otoczeniu.Zapewniamy naszym podopiecznym wielokierunkowy rozwój fizyczny (basen, 
    hipoterapia, wspinaczka, turystyka, sportowe gry zespołowe, wycieczki rowerowe i gokartowe), nabywanie nowych 
    umiejętności i pomoc w odnajdywaniu pasji.`,
  accordiconText: `Raz w roku organizujemy wyjazd na tzw. zielone szkoły. Prowadzimy autorski 
    program terapii wspinaczkowej osób niepełnosprawnych. Od 2007 roku, jesteśmy inicjatorem i organizatorem Małopolskich 
    Zawodów Wspinaczkowych Osób Niepełnosprawnych im. Jurka Gizy. Stowarzyszenie pełni ważną rolę dla rodziców osób z 
    niepełnosprawnością, którzy dzięki niemu mogą przynajmniej w części realizować swoje cele i plany zawodowe.`,
};
export default protegesData;
