import React from "react";

function Video(props) {
  return (
    <video poster={props.poster} preload="none" controls>
      <source src={props.source} type="video/mp4" />
    </video>
  );
}

export default Video;
