import React from 'react'

function CustomValueInput({ state, handleChange }) {
    return (
      <input
        className={`form-amount-btn `}
        required={state.amount > 0 ? false : true}
        type="number"
        name="amount"
        placeholder="wpisz kwotę"
        onChange={handleChange}
      />
    );
  }

export default CustomValueInput
