import React from "react";
import { Link } from "react-router-dom";

function FooterLinks() {
  return (
    <section className="footer__links">
      <Link className="h4 light" to="/Regulamin">
        Regulamin
      </Link>
      <Link className="h4 light" to="/polityka-prywatnosci">
        Polityka Prywatności
      </Link>
      <Link className="h4 light" to="/przelewy24">
        Przelewy 24
      </Link>
      <Link className="h4 light" to="/sprawozdania-finanowe">
        Sprawozdania finansowe
      </Link>
    </section>
  );
}

export default FooterLinks;
