import React, { useEffect } from "react";

function ScrollToTop() {
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    }, 400);
  }, []);

  return <></>;
}

export default ScrollToTop;
